import React from 'react';
import styled from 'styled-components';
import {Tooltip, Tag} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {DT} from 'rev.sdk.js/Components/Calendar/CalendarUtil';
import Calendar from 'rev.sdk.js/Components/Calendar';
import {prependTwoZero} from '../../Utils/TimeUtil';
import {getMeetingRoomLabel} from '../../Utils/SelectOptionLabelUtil';
import * as AppActions from '../../AppActions';
import {
  COURSE_INTERNAL_LABELS,
  ACTIVITY_INTERMAL_LABELS,
} from '../../Utils/ProductUtil';

const CATS = {
  meeting: {
    color: '#627254',
    display: '會議',
  },
  activity: {
    color: '#9055A2',
    display: '活動',
    internal_labels: [...ACTIVITY_INTERMAL_LABELS],
  },
  course: {
    color: '#6f3826',
    display: '課程',
    internal_labels: [...COURSE_INTERNAL_LABELS],
  },
  product: {
    color: '#F28585',
    display: '未分類',
  },
};

function AdminPage(props) {
  return (
    <Wrapper id="rev-AdminLandingPage">
      <div className="title">
        <h1>行事曆</h1>
        <div>
          {Object.keys(CATS).map((c, index) => (
            <span>
              <Tag color={CATS[c].color} style={{fontSize: 14}}>
                {CATS[c].display}
              </Tag>
            </span>
          ))}
        </div>
        <div style={{padding: 5}}>
          <div>課程：按照公會內部分類「在職進修、採認進修、專錄影片」。</div>
          <div>
            活動：按照公會內部分類「座談研討會、午間小品、拜會、接待、律師節活動、外部活動、外租場地、其他」。
          </div>
        </div>
      </div>

      <CustomCalendar />
    </Wrapper>
  );
}

function CustomCalendar(props) {
  const [meetings, setMeetings] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [activities, setActivities] = React.useState([]);
  const [products, setProducts] = React.useState([]);

  const [monthStartDate, setMonthStartDate] = React.useState(
    `${new Date().getFullYear()}-${prependTwoZero(
      new Date().getMonth() + 1,
    )}-${prependTwoZero(1)}`,
  );
  const [monthEndDate, setMonthEndDate] = React.useState(
    `${new Date().getFullYear()}-${prependTwoZero(
      new Date().getMonth() + 1,
    )}-${new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0,
    ).getDate()}`,
  );

  const fetchData = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      const meetings = await JStorage.fetchAllDocuments('Meeting', {
        EFF_DATE: {$regex: monthStartDate.slice(0, 7)},
        archived: {$ne: true},
        is_auto_created: {$exists: false},
      });

      const products = await JStorage.fetchAllDocuments(
        'product',
        {
          $and: [
            {
              'session.date': {
                $regex: monthStartDate.slice(0, 7),
              },
            },
            {archived: {$ne: true}},
          ],
        },
        null,
        {
          name: 1,
          session: 1,
          labels: 1,
          internal_label: 1,
        },
      );

      setMeetings(
        meetings.map((m) => {
          return {
            ...m,
            type: 'meeting',

            date: `${m.EFF_DATE || ''}`,
            start_time: `${m.START_TIME || ''}`,
            end_time: `${m.END_TIME || ''}`,
            room: getMeetingRoomLabel(m.room, m.room_note),
          };
        }),
      );

      setCourses(
        [...products]
          .filter((p) =>
            CATS['course'].internal_labels.includes(p.internal_label),
          )
          .map((p) => {
            return {
              ...p,
              type: 'course',
              date: `${p.session?.date || ''}`,
              start_time: `${p.session?.start_time || ''}`,
              end_time: `${p.session?.end_time || ''}`,
              room: getMeetingRoomLabel(p.session?.room, p.session?.room_note),
              place: `${p.session?.place || ''}`,
            };
          }),
      );

      setActivities(
        [...products]
          .filter((p) =>
            CATS['activity'].internal_labels.includes(p.internal_label),
          )
          .map((p) => {
            return {
              ...p,
              type: 'activity',
              date: `${p.session?.date || ''}`,
              start_time: `${p.session?.start_time || ''}`,
              end_time: `${p.session?.end_time || ''}`,
              room: getMeetingRoomLabel(p.session?.room, p.session?.room_note),
              place: `${p.session?.place || ''}`,
            };
          }),
      );

      setProducts(
        [...products]
          .filter((p) => {
            const notCatProduct = (() => {
              if (!!p.internal_label) {
                return (
                  !CATS['course'].internal_labels.includes(p.internal_label) &&
                  !CATS['activity'].internal_labels.includes(p.internal_label)
                );
              }

              return true;
            })();

            if (notCatProduct) {
              return true;
            }

            return false;
          })
          .map((p) => {
            return {
              ...p,
              type: 'product',
              date: `${p.session?.date || ''}`,
              start_time: `${p.session?.start_time || ''}`,
              end_time: `${p.session?.end_time || ''}`,
              room: getMeetingRoomLabel(p.session?.room, p.session?.room_note),
              place: `${p.session?.place || ''}`,
            };
          }),
      );

      AppActions.setLoading(false);
    } catch (err) {
      console.warn(err);
      AppActions.setLoading(false);
    }
  }, [monthStartDate]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <CalendarWrapper>
      <Calendar
        onClickPrev={({year, month}) => {
          setMonthStartDate(
            `${year}-${prependTwoZero(month + 1)}-${prependTwoZero(1)}`,
          );
          setMonthEndDate(
            `${year}-${prependTwoZero(month + 1)}-${prependTwoZero(
              `${new Date(year, month, 0).getDate()}`,
            )}`,
          );
        }}
        onClickNext={({year, month}) => {
          setMonthStartDate(
            `${year}-${prependTwoZero(month + 1)}-${prependTwoZero(1)}`,
          );
          setMonthEndDate(
            `${year}-${prependTwoZero(month + 1)}-${prependTwoZero(
              `${new Date(year, month, 0).getDate()}`,
            )}`,
          );
        }}
        onSelect={({year, month, date, dt}) => {}}
        renderDate={(props) => {
          const date = `${props.year}-${prependTwoZero(
            props.month + 1,
          )}-${prependTwoZero(props.date)}`;

          return (
            <DateCell
              key={props.date}
              {...props}
              meetings={meetings.filter((m) => m.EFF_DATE === date)}
              courses={courses.filter((p) => p.session.date === date)}
              activities={activities.filter((p) => p.session.date === date)}
              products={products.filter((p) => p.session.date === date)}
              onClick={(e, {freeSlots}) => {}}
            />
          );
        }}
      />
    </CalendarWrapper>
  );
}

function DateCell(props) {
  const {year, month, date, slots, classNames: _classNames, onClick} = props;
  const {meetings, courses, activities, products} = props;
  const curr = DT({year, month, date});
  let classNames = [];

  return (
    <DateCellWrapper
      className={_classNames.concat(classNames).join(' ')}
      onClick={(e) => {
        if (!classNames.includes('disabled')) {
          onClick(e, {});
        }
      }}>
      <div className="date">{date}</div>
      {[...meetings, ...courses, ...activities, ...products]
        .sort(
          (a, b) =>
            new Date(`${a.date} ${a.start_time}`).getTime() -
            new Date(`${b.date} ${b.start_time}`),
        )
        .map((item) => {
          if (item.type === 'meeting') {
            const detail_url = `/admin/meetings/?action=detail&id=${item.id}`;
            return (
              <div className="item">
                <Tooltip
                  title={`${item.start_time}-${item.end_time} ${item.room}`}>
                  <div
                    className="link"
                    onClick={() => AppActions.navigate(detail_url)}>
                    <Tag color={CATS[item.type].color}>
                      {CATS[item.type].display}
                    </Tag>
                    <sapn className="text">
                      {item.start_time} {item.MTNG_NAME}
                    </sapn>
                  </div>
                </Tooltip>
              </div>
            );
          } else {
            const detail_url = `/admin/products/?action=detail&id=${item.id}`;
            return (
              <div className="item">
                <Tooltip
                  title={`${item.start_time}-${item.end_time} ${item.place}${item.room}`}>
                  <div
                    className="link"
                    onClick={() => AppActions.navigate(detail_url)}>
                    <Tag color={CATS[item.type].color}>
                      {CATS[item.type].display}
                    </Tag>
                    <sapn className="text">
                      {item.start_time} {item.name}
                    </sapn>
                  </div>
                </Tooltip>
              </div>
            );
          }
        })}
    </DateCellWrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  width: 100%;

  & > .title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;

    & > h1 {
      margin-right: 10px;
      margin-bottom: 10px;
      font-size: 32px;
    }
  }
`;

const CalendarWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  .r-head {
    flex-grow: 0.35;
    flex-basis: 0;

    .r-cell.r-title {
      padding-bottom: 20px;
      font-weight: bold;
      font-size: 1.25rem;
      color: var(--secondColor);
      background-color: #ffffff;
    }

    .r-next:hover,
    .r-prev:hover {
      padding-bottom: 20px;
      border: none;
      background-color: transparent;
    }

    .r-next:before {
      border-top: 0.2em solid var(--secondColor);
      border-right: 0.2em solid var(--secondColor);
    }

    .r-prev:before {
      border-top: 0.2em solid var(--secondColor);
      border-left: 0.2em solid var(--secondColor);
    }
  }

  .r-weekdays {
    flex-grow: 0.3;
    flex-basis: 0;
    border-bottom: 1px solid var(--adminBorderColor);

    font-weight: bold;
    font-size: 1rem;
    line-height: 3rem;
  }

  .r-dates {
  }

  .r-cell.r-date {
    justify-content: flex-start;
    border-bottom: 1px solid var(--adminBorderColor);
  }

  .r-cell.r-date.r-selected,
  .r-today {
    border: none;
    border-bottom: 1px solid var(--adminBorderColor);
    background-color: #f1f3f5;
  }

  .r-cell.r-date:not(.disabled, .past):hover {
    border: none;
    border-bottom: 1px solid var(--adminBorderColor);
    background-color: #f1f3f5;
    color: #000000;
  }
`;

const DateCellWrapper = styled.div`
  & > .date {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  & > .item {
    width: 100%;
    padding: 5px;

    & .text {
      font-weight: 400;
      font-size: 15px;
      line-height: 1.5;
    }
  }
`;

export default AdminPage;
